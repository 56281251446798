@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@font-face {
  font-family: 'Helvetica-Bold';
  src: url('../fonts/Helvetica-Bold.ttf');
}

@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/Helvetica.ttf');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../fonts/SFProDisplay.otf');
}

@font-face {
  font-family: 'YouRe-Gone';
  src: url('../fonts/YouRe-Gone.ttf');
}

@font-face {
  font-family: 'MyriadHebrew-Regular';
  src: url('../fonts/Myriad-Hebrew-Regular.otf');
}

@font-face {
  font-family: 'Metropolis-Black';
  src: url('../fonts/Metropolis-Black.woff2');
}

@font-face {
  font-family: 'Metropolis-Bold';
  src: url('../fonts/Metropolis-Bold.woff2');
}

@font-face {
  font-family: 'Metropolis-Medium';
  src: url('../fonts/Metropolis-Medium.woff2');
}

@font-face {
  font-family: 'Metropolis-Regular';
  src: url('../fonts/Metropolis-Regular.woff2');
}
