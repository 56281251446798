@import "../../assets/scss/resources";

.app-rtl {
  .app-rtl-contacts-row {
    flex-direction: row-reverse;

    @include media(xl) {
      .app-rtl-contacts-row-item {
        &:first-child {
          margin-right: 0;
        }

        &:last-child {
          margin-right: 60px;
        }
      }
    }
  }

  .app-language-select {
    justify-content: flex-end;
  }

  label, input, textarea {
    direction: rtl;
  }

  .app-rtl-contact-button {
    margin-right: auto;
    margin-left: unset;
  }
}

.app-android-webview {
  padding-bottom: 52px;
}
