:root {
  --color-alto: #ddd;
  --color-alabaster: #fafafa;
  --color-white: #fff;
  --color-black: #000;
  --color-black-a06: rgba(0, 0, 0, .06);
  --color-black-a10: rgba(0, 0, 0, .1);
  --color-black-a16: rgba(0, 0, 0, .16);
  --color-black-a40: rgba(0, 0, 0, .1);
  --color-electric-violet: #6f00e2;
  --color-slate-gray: #778c97;
  --color-gallery: #ededed;
  --color-cerulean: #039be5;
  --color-tundora: #474747;
  --color-red: #ff0000;
  --color-dove-gray: #707070;
  --color-dove-gray-a20: rgb(112, 112, 112, .2);
  --color-manatee: #8b90a3;
  --color-iron: #cecfd0;
  --color-screamin-gray: #41ff74;
  --color-waterloo: #757295;
  --color-waterloo-a07: rgba(117, 114, 149, 0.07);
  --color-stratos: #001847;
  --color-catskill-white: #eff3f7;
  --color-mystic: #d8dfea;
  --color-athens-gray: #f6f7f9;

  --font-metropolis-bold: 'Metropolis-Bold', sans-serif;
  --font-metropolis-black: 'Metropolis-Black', sans-serif;
  --font-metropolis-medium: 'Metropolis-Medium', sans-serif;
  --font-metropolis-regular: 'Metropolis-Regular', sans-serif;
  --font-roboto-regular: 'Roboto-Regular';
  --font-helvetica: Helvetica, sans-serif;
  --font-poppins: Poppins, sans-serif;
}
