@import '../../assets/scss/resources';

.container {
  @include flex-center-center;

  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -2;
  transform: translate(-50%, -50%);
}

.backgroundText {
  font-family: 'YouRe-Gone', sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  opacity: 0.49;
  text-shadow: 11px 5px 20px rgba(97, 156, 185, 0.29);
  font-size: 100px;
  letter-spacing: -2.07px;
  color: $color-white;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

  @media only screen and (min-width: 400px) {
    font-size: 150px;
  }
  @media only screen and (min-width: 500px) {
    font-size: 180px;
  }
  @media only screen and (min-width: 650px) {
    font-size: 220px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 280px;
  }
  @media only screen and (min-width: 900px) {
    font-size: 350px;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 400px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 517px;
  }
}

.watermark {
  position: absolute;
  display: block;
  top: 30px;
  right: 20px;

  @include media(lg) {
    top: 50px;
    right: 50px;
  }
}

.header {
  font-family: Roboto, sans-serif;
  font-size: 32px;
  color: $color-gray-dark;
  font-weight: normal;
}

.subheader {
  font-family: Roboto, sans-serif;
  font-size: 21px;
  font-weight: normal;
  color: $color-gray-dark;
}

.description {
  opacity: 0.75;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  color: $color-gray-dark;
}

.link {
  width: 176px;
  height: 46px;

  @include flex-center-center;

  margin-top: 30px;
  border-radius: 44px;
  box-shadow: 0 12px 20px 0 rgba(255, 105, 105, 0.24);
  background-color: $color-primary;
  color: $color-white;
  text-transform: uppercase;
  text-decoration: none;
  font-family: SFProDisplay, sans-serif;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1.87px;
  transition: all 0.2s;

  &:hover {
    background-color: darken($color-primary, 3);
  }
}
