@import "../../../assets/scss/resources";

.InputGeneral {
  border-radius: 6px;
  border: solid 1px var(--color-black-a10);
  width: 100%;
  outline: none;
  transition: all 150ms ease;
  font-family: var(--font-metropolis-regular);
  color: var(--color-tundora);
  padding: 12px 16px;
  background: var(--color-white);

  &.Filled {
    border-color: var(--color-tundora);
    color: var(--color-tundora);
  }

  &.Error {
    border-color: var(--color-red);
    color: red;
  }

  &:focus {
    border-color: var(--color-tundora);
  }
}

textarea.InputGeneral {
  resize: none;
  height: 74px;
}

input.InputGeneral {
  height: 44px;
}
