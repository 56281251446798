@import 'palette';

@mixin text-size($size) {
  @if $size == xs {
    font-size: 8px;
  }
  @if $size == sm {
    font-size: 12px;
  }
  @if $size == md {
    font-size: 14px;
  }
  @if $size == lg {
    font-size: 16px;
  }
  @if $size == xl {
    font-size: 18px;
  }
  @if $size == xxl {
    font-size: 20px;
  }
  @if $size == xxxl {
    font-size: 22px;
  }
}

@mixin heading($type) {
  color: $color-text-dark;
  font-weight: 700;
  font-family: 'Helvetica-Bold', sans-serif;

  @if $type == 'default' {
    @include text-size(xxl);
  }
  @if $type == 'wide' {
    @include text-size(xxl);

    letter-spacing: 2px;
  }
  @if $type == 'small' {
    @include text-size(xl);
  }
  @if $type == 'extra-small' {
    @include text-size(lg);
  }
}

@mixin textCard($size) {
  font-weight: normal;
  font-family: 'Roboto-Regular', sans-serif;
  color: $color-gray-dark-3;
  @if $size == xs {
    font-size: 10px;
  }
  @if $size == sm {
    font-size: 13px;
  }
  @if $size == md {
    font-size: 15px;
  }
  @if $size == lg {
    font-size: 17px;
  }
  @if $size == xl {
    font-size: 19px;
  }
  @if $size == xxl {
    font-size: 21px;
  }
  @if $size == xxxl {
    font-size: 26px;
  }
}

@mixin headingCard($type) {
  @include textCard($type);
  font-weight: bold;
  font-family: 'Roboto-Regular', sans-serif;
  color: $color-gray-dark-3;
}

// Text styles [p, a...]

@mixin text($type) {
  font-weight: 400;
  font-family: 'Helvetica', sans-serif;

  @if $type == 'default' {
    @include text-size(sm);

    color: $color-text-light;
  }
  @if $type == 'large' {
    @include text-size(sm);

    letter-spacing: 0.7px;
    line-height: 19px;
    color: $color-text-light;

    @include media(md) {
      @include text-size(md);
    }
  }
  @if $type == 'wide' {
    @include text-size(xs);

    letter-spacing: 1.2px;
    color: $color-text-light;
  }
  @if $type == 'light' {
    @include text-size(md);

    color: $color-text-white;
  }
  @if $type == 'primary' {
    @include text-size(sm);

    color: $color-text-primary;
  }
}

@mixin heading-style($type) {
  @if $type == 'bold' {
    font-family: var(--font-metropolis-bold);
    font-size: 22px;
  }

  @if $type == 'medium' {
    font-family: var(--font-metropolis-medium);
    font-size: 17px;
  }

  color: var(--color-black);
}
