@import "../../assets/scss/resources";

.LanguageSelect {
  height: 52px;
  background: var(--color-white);
  padding: 0 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  z-index: 2;
  -webkit-tap-highlight-color: transparent;
  position: sticky;
  top: 0;
  border-top: 2px solid var(--color-dove-gray-a20);
  margin-left: auto;

  @include media(md) {
    background: transparent;
    height: unset;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    justify-content: flex-end;
    border: none;
    width: fit-content !important;
  }

  @include media(xs) {
    width: 100%;
  }

  img {
    width: 20px;
  }

  span {
    font-family: var(--font-poppins);
    font-size: 11px;
    color: var(--color-waterloo);
    margin-left: 8px;
  }

  &.IsForMobile {
    width: 100% !important;
  }
}

.ModalWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--color-black-a40);
  z-index: 4;

  * {
    user-select: none;
  }

  @include media(md) {
    background: transparent;
    width: 251px;
    height: 397px;
    overflow-y: hidden;
    left: unset;
    box-shadow: 10px 10px 40px 0 var(--color-mystic);
    border-radius: 12px;
    position: absolute;
    right: 0;
    top: unset;
    margin-top: 19px;
    margin-right: 10px;
  }
}

.ModalWrapper.Mobile {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--color-black-a40);
  z-index: 4;

  .Modal {
    width: 100vw !important;
    height: unset !important;
    padding-bottom: 18px;
  }

  .SearchWrapper {
    padding: 16px 14px 13px !important;
  }

  .ListWrapper {
    height: calc(46vh - 73px) !important;
  }
}

.Modal {
  width: 100vw;
  background: var(--color-white);
  position: absolute;
  bottom: 0;
  height: 46vh;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  z-index: 5;

  &.Webview {
    bottom: 50px;
  }

  @include media(md) {
    width: 251px;
    height: 397px;
  }

  ::-webkit-scrollbar {
    @include media(md) {
      width: 6px;
      background: var(--color-athens-gray);
    }
  }

  ::-webkit-scrollbar-thumb {
    @include media(md) {
      background-color: var(--color-electric-violet);
    }
  }
}

.SearchWrapper {
  padding: 16px 14px 13px;

  @include media(md) {
    padding: 11px 9px 9px 11px;
  }
}

.ListWrapper {
  overflow-y: scroll;
  height: calc(46vh - 73px);
  border-top: 1px solid var(--color-catskill-white);

  @include media(md) {
    height: 341px;
  }
}

.ListItem {
  padding: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    @include media(md) {
      background: var(--color-waterloo-a07);
    }
  }

  img {
    width: 20px;
  }

  span {
    font-family: var(--font-helvetica);
    color: var(--color-stratos);
    font-size: 13px;
    margin-left: 9px;
  }
}

.InputWrapper {
  position: relative;

  svg {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@include rtl {
  .InputWrapper svg {
    right: 0;
    left: 14px;
  }

  .ListItem {
    flex-direction: row-reverse;

    span {
      margin-left: 0;
      margin-right: 9px;
    }
  }
}

input.Input {
  display: block;
  background: var(--color-waterloo-a07);
  border: none;
  border-radius: 5px;
  color: var(--color-black-a40);
  font-family: var(--font-poppins);
  font-size: 11px;
  padding: 10px;

  @include media(md) {
    padding: 10px;
    height: 36px;
  }
}
